import billingPackages from "../../../../../services/packagesApi";
import ChannelSelect from "../SaleChannel/selectChannel.vue"
export default {
  name: "summary-modal",
  components: {
    ChannelSelect
  },
  props: {
    selectedChannel: {type: String},
    disableInputs: {type: Boolean},
    opportunity: { type: Object, required: true },
    title: { type: String, required: true },
    subtitle: { type: String, required: true },
    planSelected: { type: String },
    totalUsers: { type: Number },
    implantation_tec: { type: Boolean },
    implantation: { type: Boolean },
    pacakgeMessage: { type: String },
    integrationTallos: { type: Boolean },
    couponProposal: { type: Boolean },
    couponAddons: { type: Boolean },
    extraUsers: {type: Object},
    couponAddonUser: {type: Boolean},
    couponAddonChip: {type: Boolean},
    not_extra_chips: {type: Boolean},
    focalEmailProject: {type: String, required: false},
    financeEmail: {type: String, required: false},
    indicationSelected: {type: String, required: false},
    addon_chip_selected: {type: String, required: false},
    addons: {type: Array, required: false},
    planCoupon: {type: String, required: false},
    partnerSelected: {type: String}
  },
  data() {
    return {
      billingPackages: new billingPackages(),
      extra_chips_checkbox: "Chips Extras",
      pack_chips: "Pacote",
      legal_name: "Razão Social",
      document: "CNPJ",
      client_email: "Email do Cliente",
      client_phone: "Telefone / Celular",
      focal_finance_name: "Nome focal financeiro",
      focal_finance_phone: "Telefone/ Celular",
      focal_project_name: "Nome focal financeiro",
      focal_project_phone: "Telefone/ Celular",
      plan_title: "Plano",
      payment_recurrence: "Mensal / Anual",
      coupon: "Cupom de desconto",
      implantation_type_one: "Implantação",
      implantation_type_two: "Implantação  Técnica",
      tallos_integration: "Integração TALLOS",
      package_type: "Pacote",
      type_plan: "",
      extra_users: "Usuários adicionais",
      coupoun_users: "Cupom em usuários adicionais",
      coupoun_chip: "Cupom em chips adicionais",
      sales_person_name: "Nome Indicação",
      sales_person_phone: "Telefone/Celular Indicação",
      sales_person_email: "Email Indicação",
      sales_indication: "Indicação",
      qty_chips: "Chips adicionais",
      Total: 0
    };
  },
  methods: {
    plan() {
      const plans = {
        essential: "Essencial",
        professional: "Profissional",
        advanced: "Avançado",
      };
      this.type_plan = plans[this.planSelected];
    },
    proposalConfirm() {
      this.$emit("createProposal");
    },
    closeModal() {
      this.$emit("closeModal");
    },
    async calcTotal() {
      let plan = await this.billingPackages.getPlan(this.opportunity.subscription.sale_item.id)
      let calcTotal = 0
      let plan_recurrence = this.opportunity.extraDatas.yearly === true ? plan.total_value : plan.price
      calcTotal += this.calcCouponDiscount(this.convertStringToNumber(plan_recurrence), this.planCoupon)
      this.addons.forEach((data) => {
        let addon = data.addon
        let coupon = data.coupon
        let addonType = addon?.sale_item?.id?.split('-')[1]
        if (['users', 'chips'].includes(addonType)) {
          let addonValue = this.calcCouponDiscount(addon.value, coupon?.coupon?.value)
          if (this.opportunity.extraDatas.yearly) addonValue *= 12

          calcTotal += addonValue
        }
        else {
          if (addonType === "implantacao" && this.implantation || addonType === "implantacaotec" && this.implantation_tec) {
            calcTotal += this.calcCouponDiscount(addon.value, coupon)
          }
        }
      })
      this.Total = calcTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    },
    calcCouponDiscount(value, coupon) {
      value = typeof value === "number" ? value : 0
      coupon = typeof coupon === "number" ? coupon : 0
      return value - (value * (coupon / 100))
    },
    convertStringToNumber(str) {
      let cleanedStr = str.replace("R$", "");
      cleanedStr = cleanedStr.replace(/\./g, "");
      cleanedStr = cleanedStr.replace(",", ".");
      return Number(cleanedStr);
    },
  },
  mounted() {
    this.plan();
    this.calcTotal();
  },
};
