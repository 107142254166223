<template>
<FormOportunity/>
</template>

<script>
import FormOportunity from './components/Form.vue';
export default {
  components: { FormOportunity },
  name: "opportunities",

}
</script>
<style>
</style>