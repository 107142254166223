import { mapGetters } from "vuex";

export default {
  name: "channel-options",
  props: {
    opportunity: { type: Object },
    selectedChannel: { type: String },
    disableInputs: { type: Boolean },
  },
  computed: {
    ...mapGetters({
      associatedSeller: 'associatedSeller',
    })
  },
  async mounted(){
    this.$props.selectedChannel = this.associatedSeller ? 'associated' : 'TALLOS'
  },
  watch: {
    selectedChannel(value) {
      this.$emit("update-channel", value);
    },
  },
};
