import axios from "axios";
export default class midBillign {
  mid_billign = axios.create({
    baseURL: process.env.URL_RD_CONTAS,
    headers:{
      'Content-Type': 'application/json', 
       Authorization: process.env.API_KEY_RD_CONTAS
    }
  });

  async createProposal(data) {
    const response = await this.mid_billign.post("/customer/lead", data);
    return {
      response,
    };
  }
}
