import axios from "axios";
export default class billingAddonsPackage {
  
 billing_packages = axios.create({
    baseURL: process.env.VUE_APP_BILLING_API_URL,
    headers:{
      'Content-Type': 'application/json'
    }
  });

  async getpackageBillingDefault() {
    const response = await this.billing_packages.get("/packageBillingDefault");
    return response.data

  }
   async getpackageBillingMessage() {
    const response = await this.billing_packages.get("/packageBillingMessage");
    return {response}
  }
   async getpackageBillingOnboard() {
    const response = await this.billing_packages.get("/packageBillingOnboard");
    return  {response}
  
  }
  async validateCoupon(saleItem, couponName) {
    const response = await this.billing_packages.get(`/coupon/${saleItem}/${couponName}`)
    return response.data
  }
  async getUsersAddon(){
    const response = await this.billing_packages.get("/packageBillingUser")
    return response.data
  }

  async getChipAddon(){
    const response = await this.billing_packages.get("/packageBillingChip")
    return response.data
  }

  async getPlan(plan){
    const response = await this.billing_packages.get(`/billingPlans/${plan}`)
    return response.data
  }
}
